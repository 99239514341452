
<template>
  <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden"
       v-if="details || loadingDetails">
    <div v-if="details" class="text-center sticky top-0 h-full grid grid-cols-3 w-ful gap-2 overflow-hidden">
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full overflow-y-auto">
        <div class="flex flex-col h-full">
          <div class="m-2">
            <div class="text-center">
              <div class="flex justify-between" v-if="hasTable">
                <a @click="$emit('hideTable')">
                  <svg v-if="!hideTable" class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/>
                  </svg>
                  <svg v-else class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/>
                  </svg>

                </a>
                <a @click="openItemInNewTab">
                  <svg class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3z"/>
                  </svg>
                </a>
              </div>
              <p class="text-xl mt-6">{{details.properties.get('reference')}}</p>

                <div class="text-left grid grid-cols-3 gap-x-2">
                    <div v-if="details.properties.get('pdf_file')" class="w-full">
                        <custom-button class="my-2 w-full" @click.native="showPDF = true">
                            <svg class="w-6 h-6 text-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                            PDF
                        </custom-button>
                        <template v-if="showPDF">
                            <download-pdf :url="details.properties.get('pdf_file')" @removeFromDisplay="showPDF = false"></download-pdf>
                        </template>
                    </div>
                    <div v-for="(action, index) in remainingActions">
                        <template v-if="!action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <form-action :title="getTitle(action[0], action.title)" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)" @success="formSuccess"></form-action>
                        </template>
                        <template v-else-if="action[0] === 'download-pdf'">
                            <custom-button @click.native="downloadPdf()" class="my-2 w-full" :loading="pdfLoading">
<!--                                <svg class="w-6 h-6 text-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>-->
                                PDF
                            </custom-button>
                        </template>
                        <template v-else-if="action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <confirmation-export-sales-invoice v-if="action[0] === 'export-as-csv' ||action[0] === 'export-as-excel'" :action="action[1]"
                                                               :actionKey="action[0]"
                                                               :redirectOnSuccess="false"
                                                               @success="formSuccess"
                                                               :onSuccessPath="$route.path"
                                                               :buttonType="getColourType(action[0], index)"
                                                               additionalButtonClasses="my-2 w-full"
                            ></confirmation-export-sales-invoice>
                            <confirmation-action v-else :title="getTitle(action[0], action.title)" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="action[0] === 'delete-invoice'" :properties="details.properties" :buttonType="getColourType(action[0], index)" :on-success-path="action[0] === 'delete-invoice' ? '/invoices': $route.path" @success="formSuccess"

                            ></confirmation-action>
                        </template>
                    </div>
                </div>
                <div class="grid grid-cols-2 bg-v3-gray-50 bg-opacity-5 rounded-lg">
                    <div class="rounded-lg p-2">
                        <dt class="text-xs font-normal text-v3-base-800 dark:text-v3-base-300">Grand Total</dt>
                        <dd class="text-v3-gray-800 font-bold my-2 dark:text-v3-gray-200">{{details.properties.get('grand_total')}}</dd>
                    </div>
                    <div class="rounded-lg p-2">
                        <dt class="text-xs font-normal text-v3-base-800 dark:text-v3-base-300">Status</dt>
                        <dd class="text-v3-gray-800 font-bold my-2 dark:text-v3-gray-200">{{details.properties.get('status')}}</dd>
                    </div>
                </div>

                <header-link class="my-4" @click="openTab('invoice-items')" :isActive="tabOpen === 'invoice-items'" activeClass="bg-v3-gray-50 bg-opacity-20 dark:bg-v3-gray-50 dark:bg-opacity-20">
                    <template v-slot:title>
                        <div Class="text-left">
                            Invoice Items
                        </div>
                    </template>
                </header-link>
                <header-link class="my-4" @click="openTab('activity')" :isActive="tabOpen === 'activity'" activeClass="bg-v3-gray-50 bg-opacity-20 dark:bg-v3-gray-50 dark:bg-opacity-20">
                    <template v-slot:title>
                        <div Class="text-left">
                            Activity
                        </div>
                    </template>
                </header-link>
                <div class="mt-8 flex flex-col">
                    <div class="flex-1 mb-8">
                        <h3 class="text-lg leading-6 font-medium text-v3-gray-800 dark:text-v3-gray-200">
                            Payment Details
                        </h3>
                        <span class="block text-sm"><span class="font-medium">Bank/Sort Code:</span> {{details.properties.get('bank_sort_code')}}</span>
                        <span class="block text-sm"><span class="font-medium">Account Number:</span> {{details.properties.get('bank_account_number')}}</span>
                        <span class="block text-sm"><span class="font-medium">Payment Reference:</span> {{details.properties.get('reference')}}</span>
                    </div>
                    <div class="flex-1 mb-8">
                        <h3 class="text-lg leading-6 font-medium text-v3-gray-800 dark:text-v3-gray-200">
                            Other Information
                        </h3>
                        <span class="block text-sm"><span class="font-medium">Company Registration Number:</span> {{details.properties.get('company_registration_number')}}</span>
                    </div>
                </div>
            </div>

          </div>

        </div>
      </div>
      <div class="w-full rounded-xl bg-v3-base-500 dark:bg-v3-gray-900 h-full col-span-2 overflow-y-auto text-left">
        <div class="p-2 h-full w-full">
            <div v-if="tabOpen === 'invoice-items'">
                <sales-invoice-items-pagination ref="items" :voucher="details" :editing="editing" @editClicked="editDetails" @saveClicked="saveItems" @success="formSuccess"></sales-invoice-items-pagination>

            </div>
            <order-activity-feed v-if="tabOpen==='activity'" :entity="details"></order-activity-feed>
        </div>
      </div>
    </div>
    <loading v-else class="sticky top-0"></loading>

      <modal v-if="showModal" @removeFromDisplay="showModal = false" @perform="showModal = false" title="Unsaved Changes" confirmationString="" :has-confirmation-action="false">
          Please save changes before changing page.
      </modal>
  </div>
</template>
<script>
import BlankHeader from "@/v3/components/headers/BlankHeader.vue"
import HeaderLink from "@/v3/components/headers/HeaderLink.vue"
import Loading from '@/components/Loading'
import {mapGetters} from "vuex";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import VoucherDetailsPagination from "@/v3/components/pagination/VoucherDetailsPagination.vue";
import VoucherPreviewPagination from "@/v3/components/pagination/VoucherPreviewPagination.vue";
import OrderActivityFeed from "@/v3/components/OrderActivityFeed.vue";
import VoucherQaPagination from "@/v3/components/pagination/VoucherQaPagination.vue";
import SalesInvoiceItemsPagination from "@/v3/components/pagination/SalesInvoiceItemsPagination.vue";
import ConfirmationExportSalesInvoice from "@/v3/components/forms/ConfirmationExportSalesInvoice.vue";
import DownloadPdf from "@/pages/DownloadPdf.vue";
import Modal from "@/v3/components/modals/Modal.vue";

export default {
  name: 'SingleSalesInvoiceDetails',
  components: {
      Modal,
      DownloadPdf,
      ConfirmationExportSalesInvoice,
      SalesInvoiceItemsPagination,
      VoucherQaPagination,
      OrderActivityFeed,
      VoucherPreviewPagination,
      VoucherDetailsPagination,
      CustomButton,
       FormAction, ConfirmationAction,
    BlankHeader,
    HeaderLink,
    Loading
  },
    data(){
      return {
          editing: false,
          tabOpen: 'invoice-items',
          pdfLoading: false,
          savingItems: false,
          showPDF: false,
          showModal: false
      }
    },
    props:{
      details: {
          type: Object,
          default: null
      },
        hideTable: {
          type: Boolean,
            default: false
        },
        loadingDetails:{
          type: Boolean,
            default: false
        },
        hasTable:{
          type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        initials() {
            if (this.orderUser.entity && this.orderUser.entity.properties.get('name')) {
                return this.orderUser.entity.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        orderUser(orderEntity) {
            let orderUser = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("user");
                })
                .first();

            if (!orderUser) {
                return null;
            }

            return orderUser;
        },
        updateAction() {
            if (!this.details) {
                return null;
            }
            return this.details.actions.filter(action => action.name === 'update-sales-invoice').first()
        },
        updateActionData() {
            if (!this.updateAction || !this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        remainingActions() {
            return this.details.actions
                .filter(action =>
                    action.name !== 'add-new-item'
                );
        }
    },
    methods:{
        openItemInNewTab(){
            let selfLink = this.details.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            window.open(parts[1], '_blank');
        },
        editDetails(){
            this.editing = true;
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        async saveItems(){
            if (!this.savingItems) {
                this.savingItems = true;
                this.$refs.items.saveItems().then(res => {
                    this.editing = false;
                    this.savingItems = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The invoice was saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.savingItems = false;
                })
            }
        },
        removeItem(){
            let entity = {'entity': this.details};
            this.$emit('setLoading');
            this.$emit('getDetails', entity);
        },
        getTitle(name, title){
            if(name === 'delete-invoice'){
                return 'Delete';
            }
            if(name === 'record-partial-payment'){
                return 'Partial Payment';
            }if(name === 'record-full-payment'){
                return 'Full Payment';
            }

            return title;
        },
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        formSuccess(res){
            if(res && res.body) {
                let details = res.body;
                let entity = {'entity': details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);
            }else{
                let entity = {'entity': this.details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);
            }
        },
        downloadPdf(){
            this.pdfLoading = true;
            this.details.actions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf').first().perform().responseType('blob').then(res => {
                var fileURL = window.URL.createObjectURL(res.xhr.response);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                let pdfName = this.details.properties.get('pdf_name');
                fileLink.setAttribute('download', pdfName + '.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
                this.pdfLoading = false;
            }).catch(error => {
                this.pdfLoading = false;
                alert('There was an error processing your request.' + error);
                this.$emit('failure');
            });
        },
        openTab(tabName){
            if(!this.editing){
                this.tabOpen = tabName;
            }else{
                this.showModal = true;
            }
        }
    }
}
</script>
